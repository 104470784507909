<template>
  <div>
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>

    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Equipment Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="updateEquipment"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Details</span>
      </button>
    </div>

    <div
        class="grid grid-cols-4 gap-5"
        style="grid-template-rows: repeat(3, auto);"
    >
      <!-- Row 1 -->
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">SERVICE AGENT</label>
        <ContactAutocomplete
            class="w-full"
            v-model="local_equipment.service_agent"
            :forceSelection="true"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">SELLER AGENT</label>
        <ContactAutocomplete
            class="w-full"
            v-model="local_equipment.seller_agent"
            :forceSelection="true"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">OWNER</label>
        <ContactAutocomplete
            class="w-full"
            v-model="local_equipment.owner"
            :forceSelection="true"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">SOLD CONDITION</label>
        <input
            v-model="local_equipment.sold_condition"
            class="bge-input bge-input-spacing rounded"
            type="text"
        />
      </div>

      <!-- Row 2 -->
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">MAKE</label>
        <input
            v-model="local_equipment.make"
            class="bge-input bge-input-spacing rounded"
            type="text"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">MODEL</label>
        <input
            v-model="local_equipment.model_name"
            class="bge-input bge-input-spacing rounded"
            type="text"
        />
      </div>
      <div class="flex flex-col  row-span-2">
        <!-- DESCRIPTION spans two rows -->
        <label class="uppercase font-semibold text-sm">DESCRIPTION</label>
        <textarea
            v-model="local_equipment.description"
            class="bge-input bge-input-spacing rounded"
            rows="5"
        ></textarea>
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">PRODUCT CODE</label>
        <input
            v-model="local_equipment.product_code"
            class="bge-input bge-input-spacing rounded"
            type="text"
        />
      </div>

      <!-- Row 3 -->
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">ASSIGNED CALIBRATION INTERVAL</label>
        <input
            v-model="local_equipment.calibration_interval"
            class="bge-input bge-input-spacing rounded"
            type="number"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">ASSIGNED SERVICE INTERVAL</label>
        <input
            v-model="local_equipment.service_interval"
            class="bge-input bge-input-spacing rounded"
            type="number"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">SERIAL NUMBER</label>
        <input
            v-model="local_equipment.serial_number"
            class="bge-input bge-input-spacing rounded"
            type="text"
        />
      </div>

      <!-- Row 4 -->
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">DELIVERY DATE</label>
        <Calendar
            appendTo="body"
            dateFormat="dd/mm/yy"
            v-model="local_equipment.delivery_date"
            :showIcon="true"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">WARRANTY EXPIRY</label>
        <Calendar
            appendTo="body"
            dateFormat="dd/mm/yy"
            v-model="local_equipment.warranty_expiry"
            :showIcon="true"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">IS LOAN UNIT</label>
        <ToggleButton
            v-model="local_equipment.is_loan_unit"
            onLabel="YES"
            offLabel="NO"
            style="width: 10em"
        />
      </div>
      <div class="flex flex-col">
        <label class="uppercase font-semibold text-sm">IS OPERATIONAL</label>
        <ToggleButton
            v-model="local_equipment.is_operational"
            onLabel="YES"
            offLabel="NO"
            style="width: 10em"
        />
      </div>
    </div>
  </div>
</template>


<script>
import Spinner from "@/components/Spinner.vue";
import ToggleButton from "primevue/togglebutton";
import ContactAutocomplete from "@/components/AutoComplete/ContactAutoComplete.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "EquipmentInformation",
  mixins: [notificationMixin],
  components: {
    Spinner,
    ToggleButton,
    ContactAutocomplete,
  },
  props: {
    equipment: {
      type: Object,

    },
  },
  data() {
    return {
      isBusy:false,
      local_equipment: {
        ...this.equipment,
        service_agent: this.equipment.service_agent
            ? { contact_id: this.equipment.service_agent.contact_id || null, name: this.equipment.service_agent.name || null }
            : null,
        seller_agent: this.equipment.seller_agent
            ? { contact_id: this.equipment.seller_agent.contact_id || null, name: this.equipment.seller_agent.name || null }
            : null,
        owner: this.equipment.owner
            ? { contact_id: this.equipment.owner.contact_id || null, name: this.equipment.owner.name || null }
            : { contact_id: null, name: null },
        delivery_date: this.equipment.delivery_date
            ? this.formatTimestamp(this.equipment.delivery_date)
            : null,
        warranty_expiry: this.equipment.warranty_expiry
            ? this.formatTimestamp(this.equipment.warranty_expiry)
            : null,

      },
    };
  },

  methods: {
    async updateEquipment() {
      try {
        this.isBusy = true;

        // Map back the selected IDs without optional chaining
        this.local_equipment.service_agent_id = this.local_equipment.service_agent && this.local_equipment.service_agent.contact_id
            ? this.local_equipment.service_agent.contact_id
            : null;

        this.local_equipment.seller_agent_id = this.local_equipment.seller_agent && this.local_equipment.seller_agent.contact_id
            ? this.local_equipment.seller_agent.contact_id
            : null;

        this.local_equipment.owner_id = this.local_equipment.owner && this.local_equipment.owner.contact_id
            ? this.local_equipment.owner.contact_id
            : null;

        const updatedEquipment = {
          ...this.local_equipment,
          delivery_date: this.local_equipment.delivery_date
              ? this.$moment(this.local_equipment.delivery_date, 'DD-MM-YYYY').unix()
              : null,
          warranty_expiry: this.local_equipment.warranty_expiry
              ? this.$moment(this.local_equipment.warranty_expiry, 'DD-MM-YYYY').unix()
              : null,
        };

        // Update equipment data
        await this.EquipmentService.updateEquipment(this.local_equipment.equipment_id, updatedEquipment);
        this.$emit("refreshEquipment");
        this.isBusy = false;
        this.notifySuccess("Equipment updated successfully");
      } catch (error) {
        console.error("Error updating equipment:", error);
        this.notifyError("Couldn't update equipment");
        this.isBusy = false;
      }
    },

    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },

  },
};
</script>
